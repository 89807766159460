import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectsHelper from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { eventService, userService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Event = function ({ parentId }) {
  const collectionName = 'Events'
  const singleCollectionName = 'Event'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = { ...(parentId && { parentId }) }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  useEffect(
    () => setQueryParams((s) => ({ ...defaultQueryParams, ...s })),
    [parentId]
  )
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    eventService.getEvents
  )
  const createMutation = reactQuery.createMutation(
    eventService.createEvent,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  if (parentId && !getQuery.data?.count) return
  return (
    <Document
      tableActions={
        !parentId
          ? [
              {
                buttonLabel: translate.Filters,
                buttonColor: 'primary',
                onSubmit: (args) =>
                  setSearchParams({
                    ...Object.fromEntries([...searchParams]),
                    ...objectsHelper.cleanEmpty(args),
                    query: collectionName,
                  }),
                body,
                setBody,
                withClear: true,
                clearFunction: () => setBody({}),
                withForm: true,
                form: {
                  title: `${translate.Filter} ${translate[singleCollectionName]}`,
                  inputs: [
                    {
                      name: 'month',
                      label: 'Month',
                      type: 'number',
                      value: queryParams.month,
                      max: 12,
                    },
                    {
                      name: 'year',
                      label: 'Year',
                      type: 'number',
                      value: queryParams.year,
                    },
                    {
                      name: 'status',
                      label: 'Status',
                      type: 'select',
                      options: ['', 'All', ...types.eventStatusTypes].map(
                        (eventStatusType) => ({
                          label: eventStatusType,
                          value: eventStatusType,
                        })
                      ),
                      value: queryParams.status,
                    },
                    {
                      name: 'eventType',
                      label: 'Event Type',
                      type: 'select',
                      options: ['', ...types.eventsTypes].map((eventType) => ({
                        label: eventType,
                        value: eventType,
                      })),
                      value: queryParams.eventType,
                    },
                    {
                      name: 'importance',
                      label: 'Importance',
                      type: 'select',
                      options: ['', ...types.colors].map((color) => ({
                        label: color,
                        value: color,
                      })),
                      value: queryParams.importance,
                    },
                    {
                      name: 'underpayment',
                      label: 'Underpayment',
                      type: 'boolean',
                      value: queryParams.underpayment
                        ? JSON.parse(queryParams.underpayment)
                        : '',
                    },
                    {
                      name: 'overpayment',
                      label: 'Overpayment',
                      type: 'boolean',
                      value: queryParams.overpayment
                        ? JSON.parse(queryParams.overpayment)
                        : '',
                    },
                  ],
                },
                withReset: true,
                resetFunction: () => setSearchParams(),
              },
              {
                buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
                buttonColor: 'primary',
                onSubmit: (args) => {
                  const { start, end } = args
                  delete args.start
                  delete args.end
                  if (start)
                    [args.startHour, args.startMinute] = start.split(':')
                  if (end) [args.endHour, args.endMinute] = end.split(':')
                  return createMutation.mutate(args)
                },
                body,
                setBody,
                withClear: true,
                clearFunction: () => setBody({}),
                withForm: true,
                form: {
                  title: `${translate.Add} ${translate[singleCollectionName]}`,
                  inputs: [
                    {
                      name: 'eventType',
                      label: 'Event Type',
                      type: 'select',
                      options: types.eventsTypes.map((eventType) => ({
                        label: eventType,
                        value: eventType,
                      })),
                      required: true,
                    },
                    ...(body.eventType === 'Wedding'
                      ? [
                          {
                            name: 'groomName',
                            label: 'Groom Name',
                            type: 'text',
                            required: true,
                          },
                          {
                            name: 'brideName',
                            label: 'Bride Name',
                            type: 'text',
                          },
                        ]
                      : []),
                    ...(body.eventType === 'Corporate'
                      ? [
                          {
                            name: 'companyName',
                            label: 'Company Name',
                            type: 'text',
                            required: true,
                          },
                        ]
                      : []),
                    ...(body.eventType === 'Private'
                      ? [
                          {
                            name: 'name',
                            label: 'Name',
                            type: 'text',
                            required: true,
                          },
                          {
                            name: 'typeOfEvent',
                            label: 'Type Of Event',
                            type: 'text',
                            required: true,
                          },
                        ]
                      : []),
                    {
                      name: 'date',
                      label: 'Date',
                      type: 'date',
                      required: true,
                    },
                    {
                      name: 'endDate',
                      label: 'End Date',
                      type: 'date',
                    },
                    {
                      name: 'start',
                      label: 'Start Time',
                      type: 'time',
                    },
                    {
                      name: 'end',
                      label: 'End Time',
                      type: 'time',
                    },
                    {
                      name: 'location',
                      label: 'Location',
                      type: 'text',
                    },
                    {
                      name: 'locationLink',
                      label: 'Location Link',
                      type: 'text',
                    },
                    {
                      name: 'indoors',
                      label: 'Indoors',
                      type: 'boolean',
                    },
                    {
                      name: 'outdoors',
                      label: 'Outdoors',
                      type: 'boolean',
                    },
                    {
                      name: 'numberOfGuests',
                      label: 'Number Of Guests',
                      type: 'number',
                    },
                    {
                      name: 'numberOfBars',
                      label: 'Number Of Bars',
                      type: 'number',
                    },
                    {
                      name: 'softDrinks',
                      label: 'Soft Drinks',
                      type: 'boolean',
                    },
                    {
                      name: 'redBull',
                      label: 'Red Bull',
                      type: 'boolean',
                    },
                    {
                      name: 'ice',
                      label: 'Ice',
                      type: 'boolean',
                    },
                    {
                      name: 'barRental',
                      label: 'Bar Rental',
                      type: 'boolean',
                    },
                    {
                      name: 'glasses',
                      label: 'Glasses',
                      type: 'boolean',
                    },
                    {
                      name: 'waiters',
                      label: 'Waiters',
                      type: 'boolean',
                    },
                    {
                      name: 'coatMan',
                      label: 'Coat Man',
                      type: 'boolean',
                    },
                    {
                      name: 'shotsPackage',
                      label: 'Shots Package',
                      type: 'boolean',
                    },
                    {
                      name: 'contactName',
                      label: 'Contact Name',
                      type: 'text',
                    },
                    {
                      name: 'contactEmail',
                      label: 'Contact Email',
                      type: 'email',
                    },
                    {
                      name: 'contactNumber',
                      label: 'Contact Number',
                      type: 'text',
                    },
                    {
                      name: 'planner',
                      label: 'Planner',
                      type: 'text',
                    },
                    {
                      name: 'tastingSessionDate',
                      label: 'Tasting Session Date',
                      type: 'date',
                    },
                    {
                      name: 'followUp',
                      label: 'Follow Up',
                      type: 'text',
                    },
                    {
                      name: 'status',
                      label: 'Status',
                      type: 'select',
                      options: types.eventStatusTypes.map(
                        (eventStatusType) => ({
                          label: eventStatusType,
                          value: eventStatusType,
                        })
                      ),
                    },
                    {
                      name: 'reason',
                      label: 'Reason',
                      type: 'text',
                    },
                    {
                      name: 'importance',
                      label: 'Importance',
                      type: 'select',
                      options: types.colors.map((color) => ({
                        label: color,
                        value: color,
                      })),
                    },
                    {
                      name: 'users',
                      label: 'Users',
                      type: 'autocompleteMultiple',
                      queryKey: 'users',
                      getApi: userService.getUsers,
                      options: { role: 'editor' },
                      keys: { name: 'userName', _id: 'userId' },
                    },
                    {
                      name: 'note',
                      label: 'Note',
                      type: 'text',
                    },
                  ],
                },
              },
            ]
          : []
      }
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      notToShow={[
        'brideName',
        'groomName',
        'companyName',
        'typeOfEvent',
        'eventType',
        'startHour',
        'startMinute',
        'endHour',
        'endMinute',
        'indoors',
        'outdoors',
        'numberOfBars',
        'softDrinks',
        'redBull',
        'coatMan',
        'shotsPackage',
        'glasses',
        'barRental',
        'contactName',
        'contactEmail',
        'contactNumber',
        'reason',
        'tastingSessionDate',
        'note',
        'importance',
        'locationLink',
        'endDate',
        'day',
        'month',
        'year',
      ]}
      toShow={!parentId ? ['count'] : []}
      {...(parentId ? { navigate: ['events', '_id'] } : {})}
    />
  )
}
export default Event
